import React, { Component } from "react";
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

class modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newBusinessClientNotes: null,
    };
  }

  updateNewBusinessClientNotes = (event) => {
    if (event.target.value === "") {
      this.setState({
        [event.target.id]: null,
      });
    } else {
      this.setState({
        [event.target.id]: event.target.value,
      });
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.activeModal === "editCustomerBusinessClientNotes"}
        toggle={() => this.props.dismissModal("editCustomerBusinessClientNotes")}
      >
        <ModalHeader>Edit Client Notes</ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            rows="12"
            id="newBusinessClientNotes"
            onChange={(event) => this.updateNewBusinessClientNotes(event)}
            defaultValue={this.props.clientNotes}
            placeHolder={"These client notes will be sent with each order"}
          />
        </ModalBody>
        <ModalFooter>
          {this.state.newBusinessClientNotes !== null ? (
            <Button
              color="success"
              onClick={() =>
                this.props.editCustomerBusinessClientNotes(
                  this.state.newBusinessClientNotes,
                  this.props._id
                )
              }
              block
            >
              Submit Changes
            </Button>
          ) : (
            <Button block disabled>
              Submit Changes
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

export default modal;
