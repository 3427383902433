import React from "react";
import { Table, Badge } from "reactstrap";

const CustomerDetailsTableComponent = props => {
  const { business } = props.customer.local;
  const { activateModal } = props;

  const appearShorter = (title, length = 200) => {
    title ||= ''
    if (title.length > length) {
      title = title.substring(0, length) + "...";
    }
    return title;
  };

  return (
    <Table className="text-center" size="sm" hover>
      <tbody>
        <tr>
          <td className="text-left">Name</td>
          <td
            className="text-right"
            onClick={() => activateModal("editCustomerBusinessName")}
          >
            {business.name}
          </td>
        </tr>
        <tr>
          <td className="text-left">Industries</td>
          <td
            className="text-right"
            onClick={() => activateModal("editCustomerIndustries")}
          >
            {business.industries.length === 0 ? (
              <span>
                <Badge color="warning">None Set</Badge>
              </span>
            ) : (
                <span>
                  {business.industries.map(industry => (
                    <Badge color="secondary" className="mr-1">
                      {industry}
                    </Badge>
                  ))}
                </span>
              )}
          </td>
        </tr>
        <tr>
          <td className="text-left">Client Notes</td>
          <td
            className="text-right"
            onClick={() => activateModal("editCustomerBusinessClientNotes")}
          >
            {appearShorter(business.clientNotes)}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default CustomerDetailsTableComponent;
