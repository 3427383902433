import { postData } from "../../../../../utils/fetch-requests";
import types from "./types";

const setContentOrderCurrentView = (currentView, view) => (dispatch) => {
  if (currentView === view) {
    dispatch({
      type: types.NEW_CONTENT_ORDER_SET_CURRENT_VIEW,
      payload: "default",
    });
  } else {
    dispatch({
      type: types.NEW_CONTENT_ORDER_SET_CURRENT_VIEW,
      payload: view,
    });
  }
};

const setContentOrderMinimumWords = (wordCount) => (dispatch) => {
  dispatch({
    type: types.NEW_CONTENT_ORDER_SET_MINIMUM_WORDS,
    payload: wordCount,
  });
};

const setContentOrderMaximumWords = (wordCount) => (dispatch) => {
  dispatch({
    type: types.NEW_CONTENT_ORDER_SET_MAXIMUM_WORDS,
    payload: wordCount,
  });
};

const setContentOrderTurnAround = (turnAroundTime) => (dispatch) => {
  dispatch({
    type: types.NEW_CONTENT_ORDER_SET_TURNAROUND_TIME,
    payload: turnAroundTime,
  });
};

const setContentOrderDueDate = (dueDate) => (dispatch) => {
  dispatch({
    type: types.NEW_CONTENT_ORDER_SET_DUE_DATE,
    payload: dueDate,
  });
};

const setContentOrderShortCode = (boolean) => (dispatch) => {
  dispatch({
    type: types.NEW_CONTENT_ORDER_SET_COMPANY_SHORT_CODE,
    payload: boolean,
  });
};

const setContentOrderContentType = (contentType) => (dispatch) => {
  dispatch({
    type: types.NEW_CONTENT_ORDER_SET_CONTENT_TYPE,
    payload: contentType,
  });
};

const setContentOrderTemplateType = (templateType) => (dispatch) => {
  dispatch({
    type: types.NEW_CONTENT_ORDER_SET_TEMPLATE_TYPE,
    payload: templateType,
  });
};

const setContentOrderTitle = (title) => (dispatch) => {
  dispatch({
    type: types.NEW_CONTENT_ORDER_SET_ORDER_TITLE,
    payload: title,
  });
};

const setContentOrderInstructions = (desc) => (dispatch) => {
  dispatch({
    type: types.NEW_CONTENT_ORDER_SET_ORDER_INSTRUCTIONS,
    payload: desc,
  });
};

const setContentOrderDescription = (desc) => (dispatch) => {
  dispatch({
    type: types.NEW_CONTENT_ORDER_SET_ORDER_DESCRIPTION,
    payload: desc,
  });
};

const setContentOrderSpecialNotes = (notes) => (dispatch) => {
  dispatch({
    type: types.NEW_CONTENT_ORDER_SET_SPECIAL_NOTES,
    payload: notes,
  });
};

const setContentOrderTargetPlace = (currentPlace, place) => (dispatch) => {
  if (currentPlace._id === place._id) {
    dispatch({
      type: types.NEW_CONTENT_ORDER_SET_TARGETED_PLACE,
      payload: { _id: null },
    });
  } else {
    dispatch({
      type: types.NEW_CONTENT_ORDER_SET_TARGETED_PLACE,
      payload: place,
    });
  }
};

const setContentOrderFileUploads = (uploads) => (dispatch) => {
  dispatch({
    type: types.NEW_CONTENT_ORDER_SET_FILE_UPLOADS,
    payload: uploads,
  });
};

const resetContentOrderFileUploads = () => (dispatch) => {
  dispatch({
    type: types.NEW_CONTENT_ORDER_RESET_FILE_UPLOADS,
  });
};

const setFormValidity = (bool) => (dispatch) => {
  dispatch({
    type: types.NEW_CONTENT_ORDER_SET_FORM_VALIDITY,
    payload: bool,
  });
};

const submitContentOrder = (contentOrder, domainName, projectID, companyClientNotes = '') => (
  dispatch
) => {
  dispatch({
    type: types.NEW_CONTENT_ORDER_BEGIN_SENDING,
  });

  const newOrder = {
    domainName,
    projectID,
    companyClientNotes,
    title: contentOrder.orderTitle
      .replace(/\+/g, "&#43;")
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;"),
    contentMeta: contentOrder.contentMeta,
    instructions: contentOrder.orderInstructions
      .replace(/\+/g, "&#43;")
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;"),
    description: contentOrder.orderDescription
      .replace(/\+/g, "&#43;")
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;"),
    shortCode: contentOrder.shortCode,
    contentType: contentOrder.contentType,
    min_words: parseInt(contentOrder.minimumWords),
    max_words: parseInt(contentOrder.maximumWords),
    working_time: parseInt(contentOrder.turnAround),
    dueDate: contentOrder.dueDate,
    writingTeam: contentOrder.dueDateInDays > 5 ? 21270 : 21678,
    targetedPlace: contentOrder.targetedPlace,
    targetedKeywords: contentOrder.targetedKeywords,
    note:
      contentOrder.specialNotes === null
        ? ""
        : contentOrder.specialNotes
            .replace(/\+/g, "&#43;")
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;"),
  };

  postData("/api/textbroker/create-new-order", newOrder)
    .then((res) => {
      let project = res.data.doc;
      dispatch({
        type: "RECEIVE_PROJECT_DETAILS",
        payload: project,
      });
      if (newOrder.targetedKeywords.keywords.length > 0) {
        dispatch({
          type: types.NEW_CONTENT_ORDER_BEGIN_UPDATING_KEYWORDS,
        });
        postData("/api/textbroker/attach-keywords-to-order", {
          orderID: res.data.order.textBroker._id,
          keywords: newOrder.targetedKeywords,
        }).then((res) => {
          if (res.order_id_changed) {
            dispatch({
              type: types.NEW_CONTENT_ORDER_FINISHED_SENDING_SUCCESS,
            });
          }
        });
      } else {
        dispatch({
          type: types.NEW_CONTENT_ORDER_FINISHED_SENDING_SUCCESS,
        });
      }
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: types.NEW_CONTENT_ORDER_FINISHED_SENDING_FAILURE,
      });
    });
};

const retrySendingContentOrder = () => (dispatch) => {
  dispatch({
    type: types.NEW_CONTENT_ORDER_RETRY_SENDING,
  });
};

const resetContentOrderForm = () => (dispatch) => {
  dispatch({
    type: types.NEW_CONTENT_ORDER_RESET_FORM,
  });
  dispatch({
    type: "NEW_CONTENT_ORDER_RESET_META",
  });
  dispatch({
    type: "NEW_CONTENT_ORDER_RESET_KEYWORDS",
  });
};

export {
  setContentOrderMinimumWords,
  setContentOrderMaximumWords,
  setContentOrderTurnAround,
  setContentOrderCurrentView,
  setContentOrderDueDate,
  setContentOrderShortCode,
  setContentOrderContentType,
  setContentOrderTemplateType,
  setContentOrderTitle,
  setContentOrderInstructions,
  setContentOrderDescription,
  setContentOrderSpecialNotes,
  setContentOrderTargetPlace,
  setContentOrderFileUploads,
  resetContentOrderFileUploads,
  setFormValidity,
  submitContentOrder,
  retrySendingContentOrder,
  resetContentOrderForm,
};
