import { combineReducers } from "redux";
import moment from "moment";
import types from "./types";
import { default as targetedKeywords } from "../components/ContentOrderKeywords/duck/reducer";
import { default as contentMeta } from "../components/ContentMetaForm/duck/reducer";

// newOrder: {
//   minimumWords: 1,
//   maximumWords: 1,
//   useShortCode: 1,
//   contentType: 1,
//   turnAround: 0,
//   title: null,
//   description: null,
//   specialNotes: null,
//   fileAttachments: [],
//   targetKeywords: [],
//   targetLocations: []
// }

const currentView = (state = "default", action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_SET_CURRENT_VIEW: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM: {
      return "default";
    }
    default: {
      return state;
    }
  }
};

const minimumWords = (state = 1, action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_SET_MINIMUM_WORDS: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM: {
      return 1;
    }
    default: {
      return state;
    }
  }
};

const maximumWords = (state = 1, action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_SET_MAXIMUM_WORDS: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM: {
      return 1;
    }
    default: {
      return state;
    }
  }
};

const turnAround = (state = "0", action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_SET_TURNAROUND_TIME: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM: {
      return "0";
    }
    default: {
      return state;
    }
  }
};

const dueDate = (state = "0", action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_SET_DUE_DATE: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM: {
      return "0";
    }
    default: {
      return state;
    }
  }
};

const dueDateInDays = (state = "0", action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_SET_DUE_DATE: {
      let a = moment(); // today
      let b = moment(new Date(action.payload));
      let daysBetween = b.diff(a, "days") + 1; // =1
      console.log("Days Between now and Due Date:" + daysBetween);
      console.log(a);
      console.log(b.toISOString());
      return daysBetween;
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM: {
      return "0";
    }
    default: {
      return state;
    }
  }
};

const shortCode = (state = "1", action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_SET_COMPANY_SHORT_CODE: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM: {
      return 1;
    }
    default: {
      return state;
    }
  }
};

const contentType = (state = "1", action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_SET_CONTENT_TYPE: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM: {
      return 1;
    }
    default: {
      return state;
    }
  }
};

const templateType = (state = "charge", action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_SET_TEMPLATE_TYPE: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM: {
      return "charge";
    }
    default: {
      return state;
    }
  }
};

const orderTitle = (state = null, action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_SET_ORDER_TITLE: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const orderInstructions = (state = null, action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_SET_ORDER_INSTRUCTIONS: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const orderDescription = (state = null, action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_SET_ORDER_DESCRIPTION: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const specialNotes = (state = null, action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_SET_SPECIAL_NOTES: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const targetedPlace = (state = { _id: null }, action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_SET_TARGETED_PLACE: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM: {
      return { _id: null };
    }
    default: {
      return state;
    }
  }
};

const fileUploads = (state = [], action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_SET_FILE_UPLOADS: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM:
    case types.NEW_CONTENT_ORDER_RESET_FILE_UPLOADS: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const formValidity = (state = false, action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_SET_FORM_VALIDITY: {
      return action.payload;
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const formSubmitted = (state = false, action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_BEGIN_SENDING: {
      return true;
    }
    case types.NEW_CONTENT_ORDER_RETRY_SENDING: {
      return false;
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const requestStage = (state = "default", action) => {
  switch (action.type) {
    case types.NEW_CONTENT_ORDER_BEGIN_SENDING: {
      return "sending";
    }
    case types.NEW_CONTENT_ORDER_BEGIN_UPDATING_KEYWORDS: {
      return "updating keywords";
    }
    case types.NEW_CONTENT_ORDER_FINISHED_SENDING_SUCCESS: {
      return "success";
    }
    case types.NEW_CONTENT_ORDER_FINISHED_SENDING_FAILURE: {
      return "failed";
    }
    case types.NEW_CONTENT_ORDER_RETRY_SENDING: {
      return "default";
    }
    case types.NEW_CONTENT_ORDER_RESET_FORM: {
      return "default";
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  currentView,
  requestStage,
  minimumWords,
  maximumWords,
  turnAround,
  dueDate,
  dueDateInDays,
  shortCode,
  contentType,
  templateType,
  orderTitle,
  orderInstructions,
  orderDescription,
  specialNotes,
  contentMeta,
  targetedPlace,
  targetedKeywords,
  fileUploads,
  formValidity,
  formSubmitted,
});
