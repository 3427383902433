import axios from "axios";
import types from "./types";

const setCrowdContentOrderCurrentView = (currentView, view) => (dispatch) => {
  if (currentView === view) {
    dispatch({
      type: types.NEW_CROWD_CONTENT_ORDER_SET_CURRENT_VIEW,
      payload: "default",
    });
  } else {
    dispatch({
      type: types.NEW_CROWD_CONTENT_ORDER_SET_CURRENT_VIEW,
      payload: view,
    });
  }
};

const setCrowdContentOrderMinimumWords = (wordCount) => (dispatch) => {
  dispatch({
    type: types.NEW_CROWD_CONTENT_ORDER_SET_MINIMUM_WORDS,
    payload: wordCount,
  });
};

const setCrowdContentOrderMaximumWords = (wordCount) => (dispatch) => {
  dispatch({
    type: types.NEW_CROWD_CONTENT_ORDER_SET_MAXIMUM_WORDS,
    payload: wordCount,
  });
};

const setCrowdContentOrderWritingQuality = (quality) => (dispatch) => {
  dispatch({
    type: types.NEW_CROWD_CONTENT_ORDER_SET_WRITING_QUALITY,
    payload: quality,
  });
};

const setCrowdContentOrderProcessingTime = (processingTime) => (dispatch) => {
  dispatch({
    type: types.NEW_CROWD_CONTENT_ORDER_SET_PROCESSING_TIME,
    payload: processingTime,
  });
};

const setCrowdContentOrderShortCode = (boolean) => (dispatch) => {
  dispatch({
    type: types.NEW_CROWD_CONTENT_ORDER_SET_COMPANY_SHORT_CODE,
    payload: boolean,
  });
};

const setCrowdContentOrderPriority = (orderPriority) => (dispatch) => {
  dispatch({
    type: types.NEW_CROWD_CONTENT_ORDER_SET_ORDER_PRIORITY,
    payload: orderPriority,
  });
};

const setCrowdContentOrderContentType = (contentType) => (dispatch) => {
  dispatch({
    type: types.NEW_CROWD_CONTENT_ORDER_SET_CONTENT_TYPE,
    payload: contentType,
  });
};

const setCrowdContentOrderTemplateType = (templateType) => (dispatch) => {
  dispatch({
    type: types.NEW_CROWD_CONTENT_ORDER_SET_TEMPLATE_TYPE,
    payload: templateType,
  });
};

const setCrowdContentOrderTitle = (title) => (dispatch) => {
  dispatch({
    type: types.NEW_CROWD_CONTENT_ORDER_SET_ORDER_TITLE,
    payload: title,
  });
};

const setCrowdContentOrderDescription = (desc) => (dispatch) => {
  dispatch({
    type: types.NEW_CROWD_CONTENT_ORDER_SET_ORDER_DESCRIPTION,
    payload: desc,
  });
};

const setCrowdContentOrderSpecialNotes = (notes) => (dispatch) => {
  dispatch({
    type: types.NEW_CROWD_CONTENT_ORDER_SET_SPECIAL_NOTES,
    payload: notes,
  });
};

const setCrowdContentOrderTargetPlace = (currentPlace, place) => (dispatch) => {
  if (currentPlace._id === place._id) {
    dispatch({
      type: types.NEW_CROWD_CONTENT_ORDER_SET_TARGETED_PLACE,
      payload: { _id: null },
    });
  } else {
    dispatch({
      type: types.NEW_CROWD_CONTENT_ORDER_SET_TARGETED_PLACE,
      payload: place,
    });
  }
};

const setCrowdContentOrderFileUploads = (uploads) => (dispatch) => {
  dispatch({
    type: types.NEW_CROWD_CONTENT_ORDER_SET_FILE_UPLOADS,
    payload: uploads,
  });
};

const resetCrowdContentOrderFileUploads = () => (dispatch) => {
  dispatch({
    type: types.NEW_CROWD_CONTENT_ORDER_RESET_FILE_UPLOADS,
  });
};

const setFormValidity = (bool) => (dispatch) => {
  dispatch({
    type: types.NEW_CROWD_CONTENT_ORDER_SET_FORM_VALIDITY,
    payload: bool,
  });
};

const submitCrowdContentOrder =
  (CrowdContentOrder, domainName, projectID) => (dispatch) => {
    const buildDescription = (baseDescription, contentType, useShortCode) => {
      const determineTemplateUrl = (contentType) => {
        let templateURL = "";
        switch (contentType) {
          case "About Us Page":
            templateURL =
              "https://docs.google.com/document/d/1gDxih93PY1BnhWFo_bm0rMAg-ayofa2GyBFusoQA_lI/edit?usp=sharing";
            break;
          case "General Non-HVAC City Page":
            templateURL =
              "https://docs.google.com/document/d/1RIj-5CB0E4eaRHTDki2iAvzB0qMfWPK-jmPjrtraBw4/edit?usp=sharing";
            break;
          case "General Non-HVAC Service Page":
            templateURL =
              "https://docs.google.com/document/d/1SVGADZ1ttA-O3a-d0RlShw4hlhgmz-OCUKznAy-LTVE/edit?usp=sharing";
            break;
          case "HVAC City Page":
            templateURL =
              "https://docs.google.com/document/d/163ap-sq7H3KKQzFXNVGTbiMTQQYM9L1DjsX2EPlV6n8/edit?usp=sharing";
            break;
          case "City Page v2":
            templateURL =
              "https://docs.google.com/document/d/1zCIz-V51VLKNgH3iMd31_bR1tl27dL0SSRNp6TpZ-s0/edit?usp=sharing";
            break;
          case "Multiple Verticals City Page":
            templateURL =
              "https://docs.google.com/document/d/1_UZNWQRnhH7J8Rr2xBVgkIAVYJIuhyixqoRFRkWSIDI/edit?usp=sharing";
            break;
          case "Multiple Services Page":
            templateURL =
              "https://docs.google.com/document/d/1MkjaxEgrTQHxR3dYzlH1Q3l2ec-Mpc6AgV3oIie-vMs/edit?usp=sharing";
            break;
          case "HVAC Service Page":
            templateURL =
              "https://docs.google.com/document/d/1r3OFLn3X8vOqi6uUSJv9Z9afILY_dHvH-f8oHN5u4oU/edit?usp=sharing";
            break;
          case "Service Page v2":
            templateURL =
              "https://docs.google.com/document/d/1oi4yTrCml8Prsty76tIXIEBwUzytdxBmy3VYeOlz1rU/edit?usp=sharing";
            break;
          default:
            templateURL = null;
        }
        return templateURL;
      };

      let builtDescription = `Order Description: ${baseDescription}

     Template:
     ${contentType}
     Template URL:
     ${determineTemplateUrl(contentType)}

     Client's Website:
     ${domainName}
     
     Use Short Codes: ${useShortCode === "true" ? "Yes" : "No"}`;

      return builtDescription;
    };

    const newCrowdContentOrder = {
      params: {
        category_id: "1169",
        project_id: 150688,
        type: "team",
        // team_id: 15895, // logic for 14221 3 star 14098 4 star,
        team_id:
          parseInt(CrowdContentOrder.writingQuality) === 3 ? 14221 : 14098,
        processing_time: parseInt(CrowdContentOrder.processingTime),
        min_word_count: CrowdContentOrder.minimumWords.toString(),
        max_word_count: CrowdContentOrder.maximumWords.toString(),
        title: CrowdContentOrder.orderTitle,
        quality: parseInt(CrowdContentOrder.writingQuality) === 4 ? 4 : 3,
        place_order_now: true,
        description: buildDescription(
          CrowdContentOrder.orderDescription,
          CrowdContentOrder.contentType,
          CrowdContentOrder.shortCode
        ),
        website_url: domainName,
        keywords: CrowdContentOrder.targetedCrowdContentKeywords.keywords,
        target_audience: "potential customer",
        voice_tone: ["informative", "professional"],
        purpose_of_content: ["inform", "promote"],
        call_to_action_other: "Call Us / Schedule Service",
        avoid: CrowdContentOrder.specialNotes,
      },
    };

    dispatch({
      type: types.NEW_CROWD_CONTENT_ORDER_BEGIN_SENDING,
    });

    axios
      .post("/api/crowd-content/save-content-order", newCrowdContentOrder)
      .then((res) => {
        if (res.data.message === "Order created.") {
          let contentOrderForDB = {
            ...newCrowdContentOrder,
            ...CrowdContentOrder,
            order_id: res.data.order_id,
            projectID,
          };

          dispatch({
            type: types.NEW_CROWD_CONTENT_ORDER_FINISHED_SENDING_SUCCESS,
          });

          console.log(contentOrderForDB);

          axios
            .post("/api/crowd-content/save-order-in-db", contentOrderForDB)
            .then((res) => {
              console.log(res);
            });

          // store in database
        } else {
          dispatch({
            type: types.NEW_CROWD_CONTENT_ORDER_FINISHED_SENDING_FAILURE,
          });
        }
      });
  };

const retrySendingCrowdContentOrder = () => (dispatch) => {
  dispatch({
    type: types.NEW_CROWD_CONTENT_ORDER_RETRY_SENDING,
  });
};

const resetCrowdContentOrderForm = () => (dispatch) => {
  dispatch({
    type: types.NEW_CROWD_CONTENT_ORDER_RESET_FORM,
  });
  dispatch({
    type: "NEW_CROWD_CONTENT_ORDER_RESET_META",
  });
  dispatch({
    type: "NEW_CROWD_CONTENT_ORDER_RESET_KEYWORDS",
  });
};

export {
  setCrowdContentOrderMinimumWords,
  setCrowdContentOrderMaximumWords,
  setCrowdContentOrderWritingQuality,
  setCrowdContentOrderProcessingTime,
  setCrowdContentOrderCurrentView,
  setCrowdContentOrderShortCode,
  setCrowdContentOrderPriority,
  setCrowdContentOrderContentType,
  setCrowdContentOrderTemplateType,
  setCrowdContentOrderTitle,
  setCrowdContentOrderDescription,
  setCrowdContentOrderSpecialNotes,
  setCrowdContentOrderTargetPlace,
  setCrowdContentOrderFileUploads,
  resetCrowdContentOrderFileUploads,
  setFormValidity,
  submitCrowdContentOrder,
  retrySendingCrowdContentOrder,
  resetCrowdContentOrderForm,
};
