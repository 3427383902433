import { connect } from "react-redux";
import { default as Modal } from "./EditCustomerBusinessClientNotes.jsx";

// UI Actions
import { dismissModal } from "../../../common/UI/ActiveModal/duck/actions.js";
// UI Selectors
import { getActiveModal } from "../../../common/UI/ActiveModal/duck/selectors.js"

// Local Actions
import { editCustomerBusinessClientNotes } from "../../duck/actions.js";
// Local Selectors
import { getCustomerDetails } from "../../duck/selectors.js";


const mapStateToProps = state => {
  return {
    activeModal: getActiveModal(state),
    clientNotes: getCustomerDetails(state).local.business.clientNotes,
    _id: getCustomerDetails(state)._id
  };
};

const mapDispatchToProps = {
  dismissModal,
  editCustomerBusinessClientNotes
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
