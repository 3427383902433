import React, { Fragment } from "react";
import { constants } from "../../duck/constants";
import { Button, Input, InputGroup } from "reactstrap";
import moment from "moment";

const ContentOrderForm = (props) => {
  const displayMomentDueDate = (days) => {
    const today = new moment();
    const adjustedDate = today.add(days, "days").format("MM/DD/YYYY");
    return <option value={adjustedDate}>{adjustedDate}</option>;
  };

  const validateForm = () => {
    if (
      parseInt(props.minimumWords) >= parseInt(props.maximumWords) ||
      parseInt(props.minimumWords) === 1 ||
      parseInt(props.maximumWords) === 1 ||
      props.turnAround === "0" ||
      props.dueDate === "0" ||
      props.shortCode === "1" ||
      props.orderTitle === null ||
      props.orderTitle === "" ||
      props.orderTitle.length <= 2 ||
      props.orderInstructions === null ||
      props.orderInstructions === "" ||
      props.orderInstructions.length <= 10 ||
      props.orderDescription === null ||
      props.orderDescription === "" ||
      props.orderDescription.length <= 3
    ) {
      props.setFormValidity(false);
    } else {
      props.setFormValidity(true);
    }
  };

  const {
    companyName,
    companyClientNotes,
    minimumWords,
    maximumWords,
    turnAround,
    dueDate,
    shortCode,
    contentType,
    templateType,
    orderTitle,
    orderInstructions,
    orderDescription,
    specialNotes,
    setContentOrderMinimumWords,
    setContentOrderMaximumWords,
    setContentOrderTurnAround,
    setContentOrderDueDate,
    setContentOrderShortCode,
    setContentOrderContentType,
    setContentOrderTemplateType,
    setContentOrderTitle,
    setContentOrderInstructions,
    setContentOrderDescription,
    setContentOrderSpecialNotes,
  } = props;

  return (
    <Fragment>
      {templateType === null ? (
        <div className="text-center">
          <div className="col-12 mb-2">
            <strong>Is this a RYNO Charge based Order?</strong>
          </div>
          <Button
            color="primary"
            className="btn-block"
            onClick={() => setContentOrderTemplateType("Charge")}
          >
            Yes
          </Button>{" "}
          <Button
            color="secondary"
            className="btn-block"
            onClick={() => setContentOrderTemplateType("Standard")}
          >
            No
          </Button>
        </div>
      ) : (
        <form onChange={() => validateForm()}>
          <InputGroup className="mb-1">
            <Input
              type="select"
              name="minimumWords"
              id="minimumWords"
              className="form-control mr-1"
              defaultValue={minimumWords}
              onChange={(event) =>
                setContentOrderMinimumWords(event.target.value)
              }
            >
              <option value="1" disabled>
                Min. Words
              </option>
              {constants.minWordRange.map((n) => (
                <option key={n} value={n}>
                  {n}
                </option>
              ))}
            </Input>
            <Input
              type="select"
              name="maximumWords"
              id="maximumWords"
              className="form-control mr-1"
              defaultValue={maximumWords}
              onChange={(event) =>
                setContentOrderMaximumWords(event.target.value)
              }
            >
              <option value="1" disabled>
                Max. Words
              </option>
              {constants.maxWordRange.map((n) => (
                <option key={n} value={n}>
                  {n}
                </option>
              ))}
            </Input>
            <Input
              type="select"
              name="turnAround"
              id="turnAround"
              className="form-control mr-1"
              defaultValue={turnAround}
              onChange={(event) =>
                setContentOrderTurnAround(event.target.value)
              }
            >
              <option value="0" disabled>
                Turnaround?
              </option>
              <option value="1">1 days</option>
              <option value="2">2 days</option>
              <option value="3">3 days</option>
              <option value="4">4 days</option>
              <option value="5">5 days</option>
            </Input>
          </InputGroup>
          <InputGroup>
            <Input
              type="select"
              name="dueDate"
              id="dueDate"
              className="form-control mb-1 mr-1"
              defaultValue={dueDate}
              onChange={(event) => setContentOrderDueDate(event.target.value)}
            >
              <option value="0" disabled>
                Latest Return Date?
              </option>
              {displayMomentDueDate(2)}
              {displayMomentDueDate(3)}
              {displayMomentDueDate(4)}
              {displayMomentDueDate(5)}
              {displayMomentDueDate(6)}
              {displayMomentDueDate(7)}
              {displayMomentDueDate(8)}
              {displayMomentDueDate(9)}
              {displayMomentDueDate(10)}
              {displayMomentDueDate(11)}
              {displayMomentDueDate(12)}
              {displayMomentDueDate(13)}
              {displayMomentDueDate(14)}
            </Input>
            <Input
              type="select"
              name="useShortCode"
              id="useShortCode"
              className="form-control mr-1"
              value={shortCode}
              onChange={(event) => setContentOrderShortCode(event.target.value)}
            >
              <option value="1" disabled>
                Use Company Short Code?
              </option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Input>
          </InputGroup>
          {templateType === "Standard" ? (
            <Input
              type="select"
              name="contentType"
              id="contentType"
              className="form-control mr-1"
              value={contentType}
              onChange={(event) =>
                setContentOrderContentType(event.target.value)
              }
            >
              <option value="1" disabled>
                Select Content Type?
              </option>
              <option value="Blog">Blog</option>
              <option value="Home Page">Home Page</option>
              <option value="About Us Page">About Us Page</option>
              <option value="City Page">City Page</option>
              <option value="City Page | Plumbing or Electrical">
                City Page | Plumbing or Electrical
              </option>
              <option value="City Page | HVAC and Electrical">
                City Page | HVAC and Electrical
              </option>
              <option value="City Page | HVAC and Plumbing">
                City Page | HVAC and Plumbing
              </option>
              <option value="Service Page">Service Page</option>
              <option value="Service Page | Plumbing or Electrical">
                Service Page | Plumbing or Electrical
              </option>
            </Input>
          ) : (
            <Input
              type="select"
              name="contentType"
              id="contentType"
              className="form-control mr-1 mb-1"
              value={contentType}
              onChange={(event) =>
                setContentOrderContentType(event.target.value)
              }
            >
              <option value="1" disabled>
                Select Content Type?
              </option>
              <option value="HVAC City Page">HVAC City Page</option>
              <option value="HVAC Service Page">HVAC Service Page</option>
              <option value="Services Page">Services Page</option>
              <option value="Multiple Verticals City Page">
                Multiple Verticals City Page
              </option>
              <option value="Multiple Services Page">
                Multiple Services Page
              </option>
              <option value="General Non-HVAC City Page">
                General Non-HVAC City Page
              </option>
              <option value="General Non-HVAC Service Page">
                General Non-HVAC Service Page
              </option>
              <option value="City Page v2">City Page v2</option>
              <option value="Service Page v2">Service Page v2</option>
            </Input>
          )}
          <Input
            type="text"
            className="form-control mb-2"
            placeholder="Title"
            name="orderTitle"
            id="title"
            value={orderTitle}
            onChange={(event) => {
              setContentOrderTitle(event.target.value);
            }}
          />
          <Input
            type="textarea"
            rows={7}
            className="form-control mb-2"
            placeholder="Order Instructions... "
            name="orderInstructions"
            id="instructions"
            value={orderInstructions}
            onChange={(event) => {
              setContentOrderInstructions(event.target.value);
            }}
          />
          <Input
            type="textarea"
            rows={5}
            className="form-control mb-2"
            placeholder="Description... "
            name="orderDescription"
            id="description"
            value={orderDescription}
            onChange={(event) => {
              setContentOrderDescription(event.target.value);
            }}
          />
          <div className="text-center mb-2"> -- optional --</div>

          <Input
            type="textarea"
            rows={2}
            className="form-control mb-2"
            placeholder="Special Notes... "
            name="orderSpecialNotes"
            id="specialNotes"
            value={specialNotes}
            onChange={(event) => {
              setContentOrderSpecialNotes(event.target.value);
            }}
          />
        </form>
      )}
    </Fragment>
  );
};

export default ContentOrderForm;

/* <soapenv:Header/>\n
      <soapenv:Body>\n
        <urn:create soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">\n\t
          <salt xsi:type="xsd:string">${encryptedAuth.salt}</salt>\n
          <token xsi:type="xsd:string">${encryptedAuth.token}</token>\n
          <budget_key xsi:type="xsd:string">${
            config.budgetKey
          }</budget_key>\n
          <category xsi:type="xsd:positiveInteger">1</category>\n
          <title xsi:type="xsd:string">RYNO SEO TEST - Do Not Process</title>\n
          <description xsi:type="xsd:string"><![CDATA[This is just a API test on our part.<h1></h1>]]></description>\n
          <min_words xsi:type="xsd:positiveInteger">500</min_words>\n
          <max_words xsi:type="xsd:positiveInteger">700</max_words>\n
          <classification xsi:type="xsd:positiveInteger">4</classification>\n
          <working_time xsi:type="xsd:positiveInteger">2</working_time>\n
          <author xsi:type="xsd:positiveInteger">756</author>\n
          <note xsi:type="xsd:string">Do not process -- Just testing</note>\n
          <deadline xsi:type="xsd:date"></deadline>\n
        </urn:create>\n
      </soapenv:Body>\n
    </soapenv:Envelope> */
