import { connect } from "react-redux";
import { default as component } from "./addContentOrder.jsx";

// UI actions
import { dismissModal } from "../../../../common/UI/ActiveModal/duck/actions";
// UI selectors
import { getActiveModal } from "../../../../common/UI/ActiveModal/duck/selectors";

// Local selectors
import {
  getContentOrderCurrentView,
  getContentOrderDetails,
  getContentOrderFormValidity,
  getContentOrderMetaValidity,
  getContentOrderFormSubmittedStatus,
  getContentOrderRequestStage,
  getContentOrderTemplateType,
} from "./duck/selectors";
import {
  getProjectTargetedPlaces,
  getProjectDomainName,
  getProjectID,
  getProjectParentCompanyClientNotes,
} from "../../../duck/selectors.js";

// Local actions
import {
  setContentOrderCurrentView,
  submitContentOrder,
  resetContentOrderForm,
  retrySendingContentOrder,
} from "./duck/actions";

const mapStateToProps = (state) => {
  return {
    activeModal: getActiveModal(state),
    companyClientNotes: getProjectParentCompanyClientNotes(state),
    projectID: getProjectID(state),
    projectDomain: getProjectDomainName(state),
    projectTargetablePlaces: getProjectTargetedPlaces(state),
    contentOrder: getContentOrderDetails(state),
    templateType: getContentOrderTemplateType(state),
    currentView: getContentOrderCurrentView(state),
    formValidity: getContentOrderFormValidity(state),
    metaValidity: getContentOrderMetaValidity(state),
    formSubmitted: getContentOrderFormSubmittedStatus(state),
    requestStage: getContentOrderRequestStage(state),
  };
};

const mapDispatchToProps = {
  dismissModal,
  setContentOrderCurrentView,
  submitContentOrder,
  resetContentOrderForm,
  retrySendingContentOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
